import React from 'react';
import { Grid, Row, Col, Navbar, Nav, NavItem } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll';

import './main.css';

import xrimatodotisi from './xrimatodotisi.png';


class IntroPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: true,
            title1: 'Εργαλεία αναγνώρισης',
            text1: 'Η απεικόνιση της πληροφορίας μπορεί να γίνει είτε μέσω ενός pop-up παραθύρου είτε μέσω ενός info window. Τα εργαλεία αναγνώρισης στο χάρτη μπορούν να συνδυάσουν πολυμεσικό περιεχόμενο, όπως προβολή εικόνων, sliders, βίντεο, παράθυρου 360ᵒ κ.α.',
            button1: 'Είσοδος στην εφαρμογή',
            img1: "https://pogoni.gis4smart.city/img/01_pogoni_gis.png",
            title2: 'Εργαλεία χωρικής ανάλυσης',
            text2: 'Ο χρήστης μέσω των εργαλείων χωρικής ανάλυσης και φιλτραρίσματος των δεδομένων, έχει την δυνατότητα θέτοντας τόσο χωρικά όσο και περιγραφικά κριτήρια να απαντήσει σε κάθε χωρικό ερώτημα, οπτικοποιώντας τα αποτελέσματα παράλληλα με την δυνατότητα λήψης των επιλεγμένων δεδομένων',
            button2: 'Είσοδος στην εφαρμογή',
            img2: 'https://pogoni.gis4smart.city/img/02_pogoni_gis.png',
            title3: 'Χαρτογραφικά υποβάθρα',
            text3: 'Η πλατφόρμα υποστηρίζει την εναλλαγή χαρτογραφικών υποβάθρων. Συγκεκριμένα, υποστηρίζονται όλοι οι τύποι χαρτογραφικών υποβάθρων (basemaps), ενδεικτικά αναφέρονται: Google Maps, Bing Maps, MapBox Basemap, ESRI κ.α.',
            button3: 'Είσοδος στην εφαρμογή',
            img3: 'https://pogoni.gis4smart.city/img/03_pogoni_gis.png',
            title4: 'Εξαγωγή δεδομένων',
            text4: 'Μέσω της εφαρμογής επιτρέπεται η λήψη των δεδομένων του χαρτογραφικού portal σε πολλαπλούς μορφότυπους δεδομένων. Ενδεικτικά η λήψη των δεδομένων της εφαρμογής μπορεί να γίνει στις ακόλουθες μορφές αρχείων: csv, kml, shpaefile, GeoJSON, GeoPackage κ.α.',
            button4: 'Είσοδος στην εφαρμογή',
            img4: 'https://pogoni.gis4smart.city/img/04_pogoni_gis.png',
            title5: 'Οργάνωση σε θεματικά επίπεδα',
            text5: 'Η παρουσίαση των θεματικών επιπέδων του χάρτη μέσω layering, δίνει την δυνατότητα στον χρήστη να προσαρμόσει την σειρά με την οποία προβάλλονται τα δεδομένα στο περιβάλλον του χάρτη ώστε να πετύχει την βέλτιστη επιλογή οπτικοποίησης των δεδομένων ανάλογα με τις ανάγκες του',
            button5: 'Είσοδος στην εφαρμογή',
            img5: 'https://pogoni.gis4smart.city/img/05_pogoni_gis.png',
            activeTitle: 'Εργαλεία αναγνώρισης',
            activeText: 'Η απεικόνιση της πληροφορίας μπορεί να γίνει είτε μέσω ενός pop-up παραθύρου είτε μέσω ενός info window. Τα εργαλεία αναγνώρισης στο χάρτη μπορούν να συνδυάσουν πολυμεσικό περιεχόμενο, όπως προβολή εικόνων, sliders, βίντεο, παράθυρου 360ᵒ κ.α.',
            activeButton: 'Είσοδος στην εφαρμογή',
            activeImage: 'https://pogoni.gis4smart.city/img/01_pogoni_gis.png'
        };
    }

    toogleClass = (id) => {
        switch (id) {
            case 1:
                document.getElementById(1).className = "menu-tab-active";
                document.getElementById(2).className = "menu-tab";
                document.getElementById(3).className = "menu-tab";
                document.getElementById(4).className = "menu-tab";
                document.getElementById(5).className = "menu-tab";
                this.setState({
                    activeTitle: this.state.title1,
                    activeText: this.state.text1,
                    activeButton: this.state.button1,
                    activeImage: this.state.img1
                })
                break;
            case 2:
                document.getElementById(1).className = "menu-tab";
                document.getElementById(2).className = "menu-tab-active";
                document.getElementById(3).className = "menu-tab";
                document.getElementById(4).className = "menu-tab";
                document.getElementById(5).className = "menu-tab";
                this.setState({
                    activeTitle: this.state.title2,
                    activeText: this.state.text2,
                    activeButton: this.state.button2,
                    activeImage: this.state.img2
                })
                break;
            case 3:
                document.getElementById(1).className = "menu-tab";
                document.getElementById(2).className = "menu-tab";
                document.getElementById(3).className = "menu-tab-active";
                document.getElementById(4).className = "menu-tab";
                document.getElementById(5).className = "menu-tab";
                this.setState({
                    activeTitle: this.state.title3,
                    activeText: this.state.text3,
                    activeButton: this.state.button3,
                    activeImage: this.state.img3
                })
                break;
            case 4:
                document.getElementById(1).className = "menu-tab";
                document.getElementById(2).className = "menu-tab";
                document.getElementById(3).className = "menu-tab";
                document.getElementById(4).className = "menu-tab-active";
                document.getElementById(5).className = "menu-tab";
                this.setState({
                    activeTitle: this.state.title4,
                    activeText: this.state.text4,
                    activeButton: this.state.button4,
                    activeImage: this.state.img4
                })
                break;
            case 5:
                document.getElementById(1).className = "menu-tab";
                document.getElementById(2).className = "menu-tab";
                document.getElementById(3).className = "menu-tab";
                document.getElementById(4).className = "menu-tab";
                document.getElementById(5).className = "menu-tab-active";
                this.setState({
                    activeTitle: this.state.title5,
                    activeText: this.state.text5,
                    activeButton: this.state.button5,
                    activeImage: this.state.img5
                })
                break;
            default:
        }
    }

    // dimoslamiasLink() {
    //     window.open("https://www.lamia.gr/", "_self")
    // }

    // dotsoftLink() {
    //     window.open("https://www.dotsoft.gr/", "_self")
    // }

    gisLink = () => {
        window.open("http://88.99.24.44:8080/pogoni/#/viewer/openlayers/952", "_self")
    }

    dhmosLink = () => {
        window.open("https://www.pogoni.gr/", "_self")
    }

    // cityguide() {
    //     window.open("http://gis.lamia.gr/#/viewer/openlayers/123", "_self")
    // }

    // urbanplanning() {
    //     window.open("http://gis.lamia.gr/#/viewer/openlayers/131", "_self")
    // }

    render() {

        return (
            <Grid fluid={true}>
                <Row className="show-grid">
                    <Col className="header_int" xs={12} md={12} style={{ margin: 0, padding: 0 }}>
                        {/*  */}
                        <Navbar inverse collapseOnSelect className="menu_cl">
                            <div onClick={this.dhmosLink} className="logo_holder"><img style={{ maxHeight: 56 + 'px' }} src="https://gis4smart.city/static/media/LOGO_%CE%94%CE%97%CE%9C%CE%9F%CE%A5_%CE%A0%CE%A9%CE%93%CE%A9%CE%9D%CE%99%CE%9F%CE%A5-removebg-preview.a3d379f3.png" /><span style={{ marginLeft: 10 + 'px', color: '#B0B91B' }}><b>Δήμος Πωγωνίου</b></span></div>
                            <div className="container_intro">
                                <div className='xrimatodotisiCl'><img src={'.' + xrimatodotisi} width={240 + 'px'} /></div>
                                <Navbar.Header>
                                    {/* <Navbar.Toggle /> */}
                                </Navbar.Header>
                                {/* <Navbar.Collapse>
                                    <Nav pullRight>
                                        <NavItem eventKey={1} href="#">
                                            Εφαρμογές
                                        </NavItem>
                                        <NavItem eventKey={2} href="#">
                                            Έκδοση βεβαίωσης
                                        </NavItem>
                                        <NavItem eventKey={3} href="#">
                                            Αναγγελία βλάβης
                                        </NavItem>
                                    </Nav>
                                </Navbar.Collapse> */}
                            </div>
                        </Navbar>
                    </Col>
                    <Col xs={12} md={12} style={{ margin: 0, padding: 0 }}>
                        <div className="main_banner"><img src="https://pogoni.gis4smart.city/img/pogoni-zaravina-limni.jpg" width="100%" height="100%" />
                            <div className="banner_text">ΚΑΛΩΣΗΡΘΑΤΕ ΣΤΗ ΓΕΩΠΥΛΗ ΤΟΥ ΔΗΜΟΥ ΠΩΓΩΝΙΟΥ</div>
                        </div>
                    </Col>
                    <Col xs={12} md={12} align="center" style={{ backgroundColor: '#B0B91B', color: 'white', borderBottom: '1px solid black', borderTop: '1px solid black', zIndex: 100 }}>
                        <div className="text_after_banner">
                            <h2 align="left" className="welcome_text" style={{ marginBottom: 30 + 'px' }}>Ο Δήμος σας καλωσορίζει στην ολοκληρωμένη Υποδομή Γεωχωρικών Πληροφοριών (Υ.Γε.Π.) - DotSpatial</h2>
                            <p align="left">Μέσω της ιστοσελίδας αυτής, ο ενδιαφερόμενος θα βρει το σημείο εισόδου, έτσι, ώστε:</p>
                            <ul style={{ textAlign: 'left', marginLeft: 10 + 'px' }}>
                                <li>Να αξιοποιήσει τις διαφορετικές εφαρμογές διάθεσης πληροφοριών</li>
                                <li>Να ανακαλύψει χρήσιμα δεδομένα πολεοδομικής και περιβαλλοντικής φύσης, αλλά και να ενημερωθεί σχετικά με το δίκτυο υποδομών του Δήμου</li>
                                <li>Η διάθεση των πληροφοριών πραγματοποιείται μέσω δικτυακών υπηρεσιών θέασης, αναζήτησης και τηλεφόρτωσης των γεωχωρικών δεδομένων βάσει ανοικτών προτύπων (OGC). Η διαδικασία εμπλουτισμού, ανανέωσης και ολοκλήρωσης των δεδομένων βρίσκεται σε συνεχή εξέλιξη.</li>
                            </ul>
                            <p align="left">Τα πλεονεκτήματα της εφαρμογής είναι πολλαπλά, ιδιαίτερα σε επαρχιακές περιοχές όπως ο Δήμος Πωγωνίου, και κύριοι στόχοι της λειτουργίας της Υποδομής αποτελούν η ελεύθερη πρόσβαση σε ανοικτά γεωαναφερμένα δεδομένα, η άρση του γεωγραφικού αποκλεισμού, η απλοποίηση διαδικασιών και η βελτιστοποίηση της εξυπηρέτησης του πολίτη αξιοποιώντας τις ψηφιακές τεχνολογίες.</p>
                        </div>
                    </Col>
                    <Col xs={12} md={12} align="center">
                        <h2 className="titleh2">Βασικές λειτουργικότητες της Υποδομής Γεωχωρικών Πληροφοριών - DotSpatial</h2>
                        <ScrollContainer hideScrollbars="false" className="tabsCont">
                            <div className="menu-tab-active" id="1" onClick={() => {
                                this.toogleClass(1)
                            }}>
                                <div><img src="https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/applications-32.svg" width='64' height='64' /></div>
                                <li style={{ listStyleType: 'none', marginTop: 30 + 'px' }}>{this.state.title1}</li>
                            </div>
                            <div className="menu-tab" id="2" onClick={() => {
                                this.toogleClass(2)
                            }}>
                                <div><img src="https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/analysis-32.svg" width='64' height='64' /></div>
                                <li style={{ listStyleType: 'none', marginTop: 30 + 'px' }}>{this.state.title2}</li>
                            </div>
                            <div className="menu-tab" id="3" onClick={() => {
                                this.toogleClass(3)
                            }}>
                                <div><img src="https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/basemap-32.svg" width='64' height='64' /></div>
                                <li style={{ listStyleType: 'none', marginTop: 30 + 'px' }}>{this.state.title3}</li>
                            </div>
                            <div className="menu-tab" id="4" onClick={() => {
                                this.toogleClass(4)
                            }}>
                                <div><img src="https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/convert-32.svg" width='64' height='64' /></div>
                                <li style={{ listStyleType: 'none', marginTop: 30 + 'px' }}>{this.state.title4}</li>
                            </div>
                            <div className="menu-tab" id="5" onClick={() => {
                                this.toogleClass(5)
                            }}>
                                <div><img src="https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/arcgis-online-32.svg" width='64' height='64' /></div>
                                <li style={{ listStyleType: 'none', marginTop: 30 + 'px' }}>{this.state.title5}</li>
                            </div>
                        </ScrollContainer>
                    </Col>
                    <Col xs={12} md={12} align="center">
                        <div className="flex_all_cont">
                            <div className="flex_left_text">
                                <div className="flex_left_text_parent">
                                    <div className="inStyleR">
                                        <h2 align="left" className="ef_title">
                                            {this.state.activeTitle}
                                        </h2>
                                        <p align="left" style={{ marginBottom: 20 + 'px', maxWidth: 550 + 'px' }}>
                                            {this.state.activeText}
                                        </p>
                                        <button onClick={this.gisLink} className="btn-default-new">
                                            {this.state.activeButton}<span style={{ marginLeft: 10 + 'px', position: 'absolute' }}><img src="https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/arrow-right-16.svg" width="17" /></span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex_right_text">
                                <div className="flex_right_text_parent">
                                    <div>
                                        <img className="imageClass" src={this.state.activeImage}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="footerCl" xs={12} md={12}>
                        <div style={{ height: 30 + 'px', display: 'flex', alignItems: 'center', float: 'right' }}><span style={{ display: 'inline-block', marginRight: 5 + 'px' }}>Powered by</span><span style={{ marginRight: 10 + 'px', cursor: 'pointer', height: 18 + 'px' }}><a href="https://dotsoft.gr"><img src="https://dotsoft.gr/sites/default/files/logo.png" width="80px" /></a></span></div>
                        <div style={{ height: 30 + 'px', display: 'flex', alignItems: 'center', float: 'left' }}><span style={{ display: 'inline-block', marginLeft: 10 + 'px' }}>©</span></div>
                    </Col>
                </Row>
            </Grid >
        );
    }
}

export default IntroPage;
